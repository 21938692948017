<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Edit Promosi
        <div class="card-header-actions">
          <a class="card-header-action" href="promotion" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate action="https://vuejs.org/">
              <b-form-group id="name"
                            label="Nama Promosi"
                            label-for="name">
                <b-form-input id="name"
                              type="text"
                              v-model.lazy.trim="$v.form.name.$model"
                              :state="chkState('name')"
                              aria-describedby="nameFeedback"
                              placeholder="Please Enter Name Promotion"
                              autocomplete='given-name'
                              autofocus />
                <b-form-invalid-feedback id="nameFeedback" v-for="(error , index) in errors.form.name" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="promotion_type"
                          label="Promotion Type"
                          label-for="promotion_type">
                    <b-form-select id="promotion_type"
                      v-model="form.promotion_type"
                      :plain="true"
                      disabled
                      :options="[
                        {value: 'single', text: 'SINGLE'},
                        {value: 'multiple', text: 'MULTIPLE'}]">
                    </b-form-select>
                  </b-form-group>
              <p><strong>Current Date Periode</strong></p>
              <p>
                <strong>
                  {{ this.currentStart }} ~ {{ this.currentEnd }}
                </strong>
              </p>
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    class="mb-3"
                    label="Program Start From and Program End To"
                    label-for="daterange"
                  >
                    <b-input-group
                    >
                      <b-input-group-prepend is-text>
                        <i class="fa fa-calendar"></i>
                      </b-input-group-prepend>
                      <date-range-picker id="daterange" :start="startDate" :end="endDate" @picker="changePicker"></date-range-picker>
                    </b-input-group>
                    <div  v-if="this.startDate == '' " >
                      <p id="dateFeedback" v-for="(error , index) in errors.form.start" :key="index">
                        - {{ error }} <br>
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                 <b-col sm="6">
                  <b-form-group id="organization"
                                label="Organisasi/Partner"
                                label-for="organization">
                    <b-form-select id="organization"
                      v-model.lazy.trim="form.usage_type"
                      aria-describedby="organizationFeedback"
                      :plain="true"
                      disabled
                      @change="setChange"
                      :options="[
                        {value: 'All', text: 'All User'},
                        {value: 'Treasury', text: 'Treasury User'},
                        {value: 'Partner', text: 'Partner'},
                        {value: 'Organization', text: 'Organization'}]">
                    </b-form-select>
                    <b-form-invalid-feedback id="organizationFeedback" v-for="(error , index) in errors.form.usage_type" :key="index">
                      - {{ error }} <br>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group id="treasury"
                    label="Spesifik User"
                    v-if="this.form.usage_type == 'Treasury'"
                    label-for="treasury">
                <b-form-select id="treasury"
                  v-model="form.organization"
                  aria-describedby="treasuryFeedback"
                  :plain="true"
                  disabled
                  :options="[
                    {value: 1, text: 'Treasury & VIP Gold'},
                    {value: 2, text: 'Treasury Without VIP Gold'}]">
                </b-form-select>
              </b-form-group>
              <b-form-group id="partner"
                        label="Partner"
                        v-if="this.form.usage_type == 'Partner'"
                        label-for="partner">
                <b-form-select id="partner"
                  v-model="form.organization"
                  :plain="true"
                  disabled
                  :options="partner">
                </b-form-select>
              </b-form-group>
              <b-form-group id="organization"
                        label="Organization"
                        v-if="this.form.usage_type == 'Organization'"
                        label-for="organization">
                <b-form-select id="organization"
                  v-model="form.organization"
                  :plain="true"
                  disabled
                  :options="organization">
                </b-form-select>
              </b-form-group>
              <b-row>
                <b-col sm="6">
                  <b-form-group id="modul"
                          label="Modul"
                          label-for="modul">
                    <b-form-select id="modul"
                      v-model="form.module"
                      aria-describedby="moduleFeedback"
                      :plain="true"
                      disabled
                      :options="[
                        {value: 'Beli Emas', text: 'Beli Emas'}]">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group id="user_type"
                                label="User Type"
                                label-for="user_type">
                    <b-form-select id="user_type"
                      v-model="form.user_type"
                      aria-describedby="usertypeFeedback"
                      :plain="true"
                      disabled
                      :options="[
                        {value: 'All User', text: 'All User'},
                        {value: 'New User', text: 'New User'},
                        {value: 'First Time Buyer', text: 'First Time Buyer'},
                        {value: 'Last Time Buy', text: 'Last Time Buy'},
                        {value: 'Register At', text: 'Register At'}
                      ]"
                    >
                    </b-form-select>
                    <b-form-invalid-feedback id="usertypeFeedback" v-for="(error , index) in errors.form.user_type" :key="index">
                      - {{ error }} <br>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" v-if="form.user_type === 'Register At'">
                  <div>
                    <b-form-group
                      class="mb-3"
                      label="Register Start :"
                      label-for="daterange"
                    >
                      <b-input-group
                      >
                        <date-picker
                          v-model="form.register_start"
                          format="YYYY-MM-DD HH:mm:ss"
                          type="datetime"
                          lang="en"
                          value-type="format"
                          placeholder="YYYY-MM-DD HH:mm:ss"
                        ></date-picker>
                        <!-- <single-picker id="daterange" :start="startDate" @setDate="getPricing"></single-picker> -->
                      </b-input-group>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="6" v-if="form.user_type === 'Register At'">
                  <div>
                    <b-form-group
                      class="mb-3"
                      label="Register End :"
                      label-for="daterange"
                    >
                      <b-input-group
                      >
                        <date-picker
                          v-model="form.register_end"
                          format="YYYY-MM-DD HH:mm:ss"
                          type="datetime"
                          lang="en"
                          value-type="format"
                          :not-before="form.register_start"
                          placeholder="YYYY-MM-DD HH:mm:ss"
                        ></date-picker>
                        <!-- <single-picker id="daterange" :start="startDate" @setDate="getPricing"></single-picker> -->
                      </b-input-group>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-form-group id="day"
                            v-if="form.user_type === 'Last Time Buy'"
                            label="Last Time Buy Day"
                            label-for="day">
                <b-form-input id="day"
                              type="number"
                              v-model="form.day"
                              aria-describedby="dayFeedback"
                              placeholder="0"
                              autocomplete='given-name'
                              autofocus />
              </b-form-group>
              <b-row>
                <b-col sm="12" md="6" lg="6">
                  <b-form-group id="max_allowed-1"
                                label="Number of Users Allowed for Participate"
                                v-if="this.form.promotion_type === 'multiple'"
                                label-for="max_allowed">
                    <b-form-input id="max_allowed-1"
                                  type="number"
                                  v-model="form.promotion_max_usage"
                                  aria-describedby="maxAllowedFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6" lg="6">
                  <b-form-group id="maxattemp-1"
                                label="Maximum Usage Per User"
                                v-if="this.form.promotion_type === 'multiple'"
                                label-for="maxattemp">
                    <b-form-input id="maxattemp-1"
                                  type="number"
                                  v-model="form.promotion_max_attempt"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
              </b-row>
              <div v-for="(item, index) in buy" :key="index">
                <b-row v-show="form.promotion_type === 'single' && item.type === activeItem && item.hashed_id === activeAmount || form.promotion_type !== 'single'">
                  <b-col sm="12" md="2" lg="2">
                    <b-form-group :id="`sku-${index}`"
                                  label="Nilai Transaksi"
                                  :label-for="`sku--${index}`">
                      <b-form-input :id="`sku-${index}`"
                                    type="number"
                                    v-model="item.name"
                                    placeholder="0"
                                    autocomplete='given-name'
                                    autofocus />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="2" lg="2">
                    <b-form-group id="type-1"
                                  label="Tipe Promosi"
                                  label-for="type-1">
                      <b-form-select id="type-1"
                        v-model="item.type"
                        aria-describedby="usertypeFeedback"
                        :plain="true"
                        disabled
                        :options="[
                          {value: '', text: 'Pilih Tipe Promosi'},
                          {value: 'fixed', text: 'Fixed Price'},
                          {value: 'discount', text: 'Discount'},
                          {value: 'gram', text: 'Add Gram'},
                          {value: 'gram_percent', text: 'Gram Percentage'}]">
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="2" lg="2">
                    <b-form-group id="amount-1"
                                  label="Nilai Promosi"
                                  label-for="maxattemp">
                      <b-form-input id="amount-1"
                                    type="number"
                                    v-model="item.amount"
                                    aria-describedby="maxattempFeedback"
                                    placeholder="0"
                                    autocomplete='given-name'
                                    autofocus />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="2" lg="2" v-if="item.type  === 'discount' ||item.type  === 'gram_percent'">
                    <b-form-group id="amount-1"
                                  :label="item.type  === 'discount' ? 'Max Amount Promosi' : 'Max Gramation Promosi'"
                                  label-for="maxattemp">
                      <b-form-input id="amount-1"
                                    type="number"
                                    v-model="item.max_amount"
                                    aria-describedby="maxattempFeedback"
                                    placeholder="0"
                                    autocomplete='given-name'
                                    autofocus />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="2" lg="2">
                    <b-form-group id="max_allowed-1"
                                  label="Quota (Jumlah User)"
                                  v-if="form.promotion_type === 'single'"
                                  label-for="max_allowed">
                      <b-form-input id="max_allowed-1"
                                    type="number"
                                    v-model="item.max_usage"
                                    aria-describedby="maxAllowedFeedback"
                                    placeholder="0"
                                    autocomplete='given-name'
                                    autofocus />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="2" lg="2">
                    <b-form-group id="maxattemp-1"
                                  label="Maximum Usage Per User"
                                  v-if="form.promotion_type === 'single'"
                                  label-for="maxattemp">
                      <b-form-input id="maxattemp-1"
                                    type="number"
                                    v-model="item.max_attempt"
                                    aria-describedby="maxattempFeedback"
                                    placeholder="0"
                                    autocomplete='given-name'
                                    autofocus />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required} from "vuelidate/lib/validators"
import axios from 'axios'
import dotenv from 'dotenv'
dotenv.config()

// const end = helpers('end', this.form.end_to <= this.form.start_from)
// const decimal = helpers.regex('decimal', /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/)

export default {
  name: "edit-promotion",
  data() {
    return {
      startDate: '',
      endDate: '',
      partner: [],
      currentStart: '',
      currentEnd: '',
      organization: [],
      form: {
        name: '',
        type: '',
        day: '',
        amount: '',
        user_type: 'All User',
        module: 'Beli Emas',
        register_at: '',
        register_end: '',
        usage_type: 'All',
        max_attempt: '',
        organization: '',
        promotion_type: 'single',
        promotion_max_attempt: 1,
        promotion_max_usage: ''
      },
      arrButton: [],
      buy: [
        {
          hashed_id: '',
          name: '5000',
          type: '',
          amount: '',
          max_amount: '',
          max_attempt: 1,
          max_usage: ''
        },
        {
          hashed_id: '',
          name: '10000',
          type: '',
          amount: '',
          max_amount: '',
          max_attempt: 1,
          max_usage: ''
        },
        {
          hashed_id: '',
          name: '20000',
          type: '',
          amount: '',
          max_amount: '',
          max_attempt: 1,
          max_usage: ''
        },
        {
          hashed_id: '',
          name: '50000',
          type: '',
          amount: '',
          max_amount: '',
          max_attempt: 1,
          max_usage: ''
        },
        {
          hashed_id: '',
          name: '100000',
          type: '',
          amount: '',
          max_amount: '',
          max_attempt: 1,
          max_usage: ''
        },
        {
          hashed_id: '',
          name: '500000',
          type: '',
          amount: '',
          max_amount: '',
          max_attempt: 1,
          max_usage: ''
        }
      ],
      activeItem: '',
      activeAmount: '',
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          name: [],
          prefix: [],
          start: [],
          end: [],
          qty: [],
          max_attempt: [],
          max_allowed: [],
          used_for: [],
          v_type: [],
          expired_type: [],
          amount: []
        },
      },
      date: "",
      isLoading: false,
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  created() {
    this.isLoading = true
    this.$http.get(`promotion/${this.$route.params.id}?edit=true`)
      .then((result) => {
        const res = result.data.data
        this.form.name = res.name
        this.form.promotion_type = res.type
        this.form.usage_type = res.active_promotion.usage_type
        this.form.user_type = res.active_promotion.user_type
        this.form.organization = res.active_promotion.used_for
        this.currentStart = res.start_from
        this.currentEnd = res.end_to
        this.activeItem = res.active_promotion.type
        this.activeAmount = res.active_promotion.hashed_id
        this.form.register_start = res.active_promotion.register_start
        this.form.register_end = res.active_promotion.register_end
        this.form.day = res.active_promotion.day === null ? '' : res.active_promotion.day
        res.detail.map((v, i) => {
          this.arrButton[i] = v
        })
        const item_promotion = res.detail.filter(e => e.type !== null)
        this.buy[0].hashed_id = res.detail[0].hashed_id
        this.buy[0].name = res.detail[0].item
        this.buy[0].type = res.detail[0].type === null ? '' : res.detail[0].type
        this.buy[0].amount = res.detail[0].amount
        this.buy[0].max_amount = res.detail[0].max_amount
        this.buy[0].max_attempt = res.detail[0].max_attempt === null ? '' : res.detail[0].max_attempt
        this.form.promotion_max_usage = item_promotion.length > 0 ? item_promotion[0].quantity : ''
        this.form.promotion_max_attempt = item_promotion.length > 0 ? item_promotion[0].max_attempt : ''
        this.buy[0].max_usage = res.detail[0].quantity

        this.buy[1].hashed_id = res.detail[1].hashed_id
        this.buy[1].name = res.detail[1].item
        this.buy[1].type = res.detail[1].type === null ? '' : res.detail[1].type
        this.buy[1].amount = res.detail[1].amount
        this.buy[1].max_amount = res.detail[1].max_amount
        this.buy[1].max_attempt = res.detail[1].max_attempt === null ? '' : res.detail[1].max_attempt
        this.buy[1].max_usage = res.detail[1].quantity

        this.buy[2].hashed_id = res.detail[2].hashed_id
        this.buy[2].name = res.detail[2].item
        this.buy[2].type = res.detail[2].type === null ? '' : res.detail[2].type
        this.buy[2].amount = res.detail[2].amount
        this.buy[2].max_amount = res.detail[2].max_amount
        this.buy[2].max_attempt = res.detail[2].max_attempt === null ? '' : res.detail[2].max_attempt
        this.buy[2].max_usage = res.detail[2].quantity

        this.buy[3].hashed_id = res.detail[3].hashed_id
        this.buy[3].name = res.detail[3].item
        this.buy[3].type = res.detail[3].type === null ? '' : res.detail[3].type
        this.buy[3].amount = res.detail[3].amount
        this.buy[3].max_amount = res.detail[3].max_amount
        this.buy[3].max_attempt = res.detail[3].max_attempt === null ? '' : res.detail[3].max_attempt
        this.buy[3].max_usage = res.detail[3].quantity

        this.buy[4].hashed_id = res.detail[4].hashed_id
        this.buy[4].name = res.detail[4].item
        this.buy[4].type = res.detail[4].type === null ? '' : res.detail[4].type
        this.buy[4].amount = res.detail[4].amount
        this.buy[4].max_amount = res.detail[4].max_amount
        this.buy[4].max_attempt = res.detail[4].max_attempt === null ? '' : res.detail[4].max_attempt
        this.buy[4].max_usage = res.detail[4].quantity

        this.buy[5].hashed_id = res.detail[5].hashed_id
        this.buy[5].name = res.detail[5].item
        this.buy[5].type = res.detail[5].type === null ? '' : res.detail[5].type
        this.buy[5].amount = res.detail[5].amount
        this.buy[5].max_amount = res.detail[5].max_amount
        this.buy[5].max_attempt = res.detail[5].max_attempt === null ? '' : res.detail[5].max_attempt
        this.buy[5].max_usage = res.detail[5].quantity

        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
  },
  methods: {
    changePicker (value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    setChange(val) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
      if (val === 'Partner') {
        axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/partner-command').then((response) => {
          this.partner = response.data.data
        })
      }

      if (val === 'Organization') {
        axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/organization-command').then((response) => {
          this.organization = response.data.data
        })
      }
    },
    onSubmit() {
      this.validate()
      console.log(this.arrButton)
      // if (this.validate()) {
      //   this.$nextTick(() => {
        this.isLoading = true
          const formData =  new FormData();
          formData.append("_method", 'put');
          formData.append("name", this.form.name);
          formData.append("module", this.form.module);
          if (this.startDate === '') {
            formData.append("start_from", this.currentStart);
            formData.append("end_to", this.currentEnd);
          } else {
            formData.append("start_from", this.startDate);
            formData.append("end_to", this.endDate);
          }
          formData.append("used_for", this.form.usage_type === 'All' ? 0 : this.form.organization)
          formData.append("usage_type", this.form.usage_type)
          formData.append("user_type", this.form.user_type)
          formData.append("register_start", this.form.register_start)
          formData.append("register_end", this.form.register_end)
          formData.append("days", this.form.day)
          formData.append("promotion_type", this.form.promotion_type)
          if (this.form.promotion_type === 'single') {
            this.buy.map((v, i) => {
              formData.append("buy[" + i + "][hashed_id]", v.hashed_id)
              formData.append("buy[" + i + "][item]", v.name)
              formData.append("buy[" + i + "][type]", v.type)
              formData.append("buy[" + i + "][amount]", v.amount)
              if (v.type === 'discount' || v.type === 'gram_percent') {
               formData.append("buy[" + i + "][max_amount]", v.max_amount)
              }
              formData.append("buy[" + i + "][max_allowed]", v.max_usage === null ? '' : v.max_usage)
              formData.append("buy[" + i + "][max_attempt]", v.max_attempt)
            })
            this.arrButton.map((v, i) => {
              formData.append("buy[" + i + "][position]", v.position)
            })
          } else {
            this.buy.map((v, i) => {
              formData.append("buy[" + i + "][hashed_id]", v.hashed_id)
              formData.append("buy[" + i + "][item]", v.name)
              formData.append("buy[" + i + "][type]", v.type)
              formData.append("buy[" + i + "][amount]", v.amount)
              if (v.type === 'discount' || v.type === 'gram_percent') {
               formData.append("buy[" + i + "][max_amount]", v.max_amount)
              }
              formData.append("buy[" + i + "][max_allowed]", this.form.promotion_max_usage === null ? '' : this.form.promotion_max_usage)
              formData.append("buy[" + i + "][max_attempt]", this.form.promotion_max_attempt === null ? '' : this.form.promotion_max_attempt)
            })
            this.arrButton.map((v, i) => {
              formData.append("buy[" + i + "][position]", v.position)
            })
          }

          this.$http.post(`promotion/${this.$route.params.id}`, formData)
          .then(() => {
            this.isLoading  = false
            this.$router.push("/promotion");
            this.$toasted.success('Promotion successfully updated!')
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 422) {
                this.$toasted.error('Please fill out the form that must be required')
                this.errors.message = error.response.data.meta.message;
                this.errors.form.name = this.errors.message.name;
                this.errors.form.v_type = this.errors.message.type;
                this.errors.form.qty = this.errors.message.quantity;
                this.errors.form.max_attempt = this.erros.message.max_attempt;
                this.errors.form.amount = this.errors.message.amount;
                this.errors.form.prefix = this.errors.message.prefix;
                this.errors.form.start = this.errors.message.start_from;
              }else if(this.errors.status == 400){
                this.errors.message = error.response.data.meta.message;
                this.$swal.fire(
                  'Failed!',
                  this.errors.message,
                  'error'
                )
              }
            }
          })
      //   })

      // }
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  },
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.mx-datepicker {
  width: 100% !important;
}

#dateFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
